<template>
    <div>
        <ais-stats>
            <template #default="{ nbHits }">
                <div class="mb-4 flex justify-between">
                    <div class="text-grind">
                        FILTER BY ({{ nbHits }} RESULTS)
                    </div>
                    <ais-clear-refinements>
                        <template #default="{ canRefine, refine, createURL }">
                            <a
                                :href="createURL()"
                                :class="{
                                    'pointer-events-none no-underline opacity-70':
                                        !canRefine,
                                }"
                                @click.prevent="refine"
                            >
                                Reset
                            </a>
                        </template>
                    </ais-clear-refinements>
                </div>
            </template>
        </ais-stats>

        <ThemeFilterRefinementList
            title="Product Type"
            attribute="product_type"
            :sort-by="['name']"
            operator="or"
            :expanded-default="true"
            :mobile-menu-open="mobileMenuOpen"
        />

        <ThemeFilterRefinementList
            title="Theme"
            attribute="theme_group"
            :sort-by="['name']"
            operator="or"
            :expanded-default="true"
            :mobile-menu-open="mobileMenuOpen"
        />

        <ThemeFilterRefinementList
            title="Capacity"
            attribute="capacity"
            :sort-by="['name']"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />

        <ThemeFilterRefinementList
            title="Eco-friendly"
            attribute="eco_friendly"
            :sort-by="['name']"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />

        <ThemeFilterRefinementList
            title="Minimum Order Quantity"
            attribute="minimum"
            :sort-by="sortNumeric"
            operator="or"
            :mobile-menu-open="mobileMenuOpen"
        />
    </div>
</template>

<script setup lang="ts">
import { AisClearRefinements, AisStats } from 'vue-instantsearch/vue3/es';
import ThemeFilterRefinementList from '~/components/page-building/design-lab-pick-your-product/components/ThemeFilterRefinementList.vue';

const props = defineProps<{
    mobileMenuOpen?: boolean;
}>();

const sortNumeric = (a: string, b: string) => {
    return parseInt(a) - parseInt(b);
};
</script>
